<template>
  <iframe
    ref="iFrameGame"
    frameborder="0"
    allowfullscreen
    style="
      width: 100%;
      height: 100vh;
      position: relative;
      border: 0px;
      outline: none;
      overflow: hidden;
      margin: 0px;
      padding: 0px;
    "
    onload="ResizeIFrame()"
    src=""
  />
</template>

<script>
import // BInputGroup,
// BInputGroupPrepend,
// BRow,
// BCol,
// BImg,
// BFormInput,
'bootstrap-vue'
// import router from '@/router'

export default {
  components: {
    // BImg,
    // BRow,
    // BCol,
    // BInputGroupPrepend,
    // BInputGroup,
    // BFormInput,
  },
  data() {
    return {
      checkClick: true,
      game_list: '',
      gamename: '',
      balance: '',
      GameCode: '',
      token: localStorage.getItem('token'),
      userData: JSON.parse(localStorage.getItem('userData')),
      /* eslint-disable global-require */
    }
  },
  mounted() {
    console.log(this.$route.params.id)
    this.GameCode = this.$route.params.id
    window.addEventListener(
      'message',
      e => {
        if (e == null || e.data == null) {
          return
        }
        // eslint-disable-next-line default-case
        switch (e.data.event) {
          case 'ReadyToPlay':
            this.ResizeIFrame()
            break
        }
      },
      false,
    )
  },
  methods: {
    ResizeIFrame() {
      this.$ref.iFrameGame.contentWindow.postMessage(
        {
          event: 'iFrameSize',
          innerWidth: window.innerWidth,
          clientWidth: document.body.clientWidth,
          innerHeight: window.innerHeight,
          clientHeight: document.body.clientHeight,
        },
        '*',
      )
    },
    getgamelistby(type) {
      // console.log(type)
      this.game_list = ''
      const params = {
        type,
      }
      this.$http
        .get('https://api.ufabangkok.com/api/game/list', { params })
        .then(response => {
          // console.log(response.data.ListGames)
          this.show = false
          this.game_list = response.data
        })
        .catch(error => console.log(error))
    },
    getgamelistbyname(type) {
      console.log(type)
      this.game_list = ''
      const params = {
        name: this.gamename,
      }
      this.$http
        .get('https://api.ufabangkok.com/api/game/findname', { params })
        .then(response => {
          // console.log(response.data.ListGames)
          this.show = false
          this.game_list = response.data
        })
        .catch(error => console.log(error))
    },
    getgamelistall() {
      this.game_list = ''
      this.$http
        .get('https://api.ufabangkok.com/api/game/list')
        .then(response => {
          // console.log(response.data.ListGames)
          this.show = false
          this.game_list = response.data
        })
        .catch(error => console.log(error))
    },
    getbalance(username) {
      const params = {
        username,
      }
      this.$http
        .get('https://api.ufabangkok.com/api/balance/getbalance', { params })
        .then(response => {
          this.show = false
          this.balance = response.data
        })
        .catch(error => console.log(error))
    },
    refresh() {
      this.getbalance(this.userData.username)
      // this.today = moment(new Date()).format('DD/MM/YYYY')
      // this.timenow = moment(new Date()).format('HH:mm')
    },
    playgame(key) {
      if (Number(this.balance.balance) <= 0) {
        this.SwalError('คุณมียอดเครดิตไม่เพียงพอสำหรับการเล่น กรุณาเติมเครดิต')
      } else if (this.checkClick !== true) {
        this.SwalError('กรุณารอสักครู่')
      } else {
        this.checkClick = false
        setTimeout(() => {
          this.checkClick = true
        }, 5000)
        const token = localStorage.getItem('token')
        if (key.GameType === 'Live') {
          window.open(
            `http://gwc788.net/PlayGame?token=${token}&appID=TGFQ&gameCode=${key.GameCode}`,
            '_blank',
          )
        } else {
          window.open(
            `http://gwc788.net/playGame?token=${token}&appID=TGFQ&gameCode=${key.GameCode}&language=en&mobile=false&redirectUrl=https://play.joker168s.com/games`,
            '_blank',
          )
        }
      }
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: 'Error!',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
body {
  background-image: url('/bg-jk.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.column1 {
  float: left;
  width: 16.6666666667%;
  font-size: 16px;
  color: #fff;
  padding: 0;
}

.row1 {
  margin: 0 -5px;
}

.row1:after {
  content: '';
  display: table;
  clear: both;
}
.card1 {
  padding: 1px;
  text-align: center;
}
.img1 {
  max-width: 80px;
}
i {
  color: #ffc800;
}
.icon-img {
  max-height: 47px;
}
.icon-img2 {
  max-height: 79px;
}
ul {
  margin: 0 !important;
  padding: 0;
}
.box_menu {
  display: flex;
  padding: 0 15px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  overflow: hidden;
  width: 20%;
  justify-content: center;
}
.a .box_content1 {
  padding: 0 15px;
  text-align: left;
  color: #fff;
  font-size: 18px;
  overflow: hidden;
  width: 50%;
}
.box_content2 {
  padding: 0 15px;
  text-align: right;
  color: #fff;
  font-size: 18px;
  overflow: hidden;
  float: right;
  position: relative;
  width: 50%;
  top: -20px;
}
.all_message {
  background: linear-gradient(to bottom, #fae5ed, #e2e2e2);
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 0 15px;
  color: #000;
  height: 20px;
  overflow: hidden;
  width: 20%;
  float: right;
  position: relative;
  top: -20px;
}
.txtall_message {
  position: relative;
  font-size: 13px;
  color: #000 !important;
  white-space: nowrap;
  top: -6px;
  left: -5px;
}
.marquee {
  background: linear-gradient(to bottom, #cb356b, #bd3f32);
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  padding: 0 15px;
  text-align: center;
  color: #fff;
  height: 20px;
  font-size: 16px;
  overflow: hidden;
  width: 80%;
}
.bg1 {
  background: linear-gradient(180deg, rgb(28, 1, 40) 50%, rgb(52, 1, 77) 100%);
}
.wp2 {
  /* margin-top: -5px; */
  /* margin: 20px auto; */
  max-width: 640px;
  background: linear-gradient(#3d4246, #24282b);
  height: 90px;
  border-radius: 4px;
  border-top: 1px solid #53575c;
  border-left: 1px solid #53575c;
  box-shadow: 1px 1px #17191c, 0 0 10px #323338;
  box-shadow: 1px 1px 10px #b20bf4;
  padding: 8px;
  padding-bottom: 12px;
}
i b {
  margin-top: 20px;
  font-size: 24px;
}
.bgame {
  background-color: #140014;
  border: 1px solid;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #6610f2;
}
.small {
  position: relative;
  text-align: center;
  color: #fff;
  font-size: 16px;
  /* text-shadow: 0 0 5px #0bf4f3, 0 0 10px #0bf4f3, 0 0 20px #0bf4f3,
    0 0 40px #0bf4f3, 0 0 80px #0bf4f3; */
}

.listGame {
  margin-top: 15px;
  margin-left: -5px;
  margin-right: -5px;
  display: flex;
  flex-flow: wrap;
}
.item-game {
  position: relative;
  width: 210px;
  display: inline-block;
  margin-bottom: 15px;
  animation-duration: 0.5s;
}
@media (max-width: 650px) and (min-width: 375px) {
  .item-game {
    width: 115px;
    height: auto;
    position: relative;
  }
}
@media (max-width: 650px) and (min-width: 375px) {
  .img-game {
    width: 100%;
    height: auto;
    position: relative;
  }
}
@media (max-width: 440px) {
  .img-game {
    width: 160px;
    height: 149px;
  }
}
@media (min-width: 360px) and (max-width: 400px) {
  .img-game {
    min-height: 95px;
  }
}
.zoomIn {
  animation-name: zoomIn;
}
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.game-container .listGame .item-game .l-game {
  position: relative;
  display: block;
}
.l-game {
  position: relative;
  display: block;
}
.l-game:before {
  content: '';
  position: absolute;
  width: 192px;
  height: 138px;
  top: 41px;
  left: 9px;
  position: absolute;
  background: url(http://www.joker123.net/Themes/Joker/mobile/assets/images/box-game.png?v=17)
    no-repeat;
  background-size: 191px 137px;
}
.new {
  position: absolute;
  width: 67px;
  max-width: 67px;
  height: 68px;
  background: url(http://www.joker123.net/Themes/Joker/mobile/assets/images/flag-new.png?v=17)
    no-repeat;
  top: 21%;
  right: 2%;
  z-index: 5;
}
.hot {
  position: absolute;
  width: 66px;
  max-width: 66px;
  height: 67px;
  background: url(http://www.joker123.net/Themes/Joker/mobile/assets/images/flag-hot.png?v=17)
    no-repeat;
  z-index: 5;
  left: 3.333%;
  top: 20.5%;
}
.img-game {
  position: relative;
  z-index: 2;
  width: 210px;
  height: 186px;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

@media (max-width: 650px) and (min-width: 375px) {
  .listGame {
    width: 100%;
  }
}
@media (max-width: 440px) {
  .game-container .listGame .item-game {
    width: 160px;
  }
}
@media (max-width: 580px) {
  .new {
    top: 21%;
    width: 31px;
    height: 32px;
    background-size: 30px 32px;
  }
}
@media (max-width: 480px) {
  .hot {
    left: 2.8%;
    top: 20.5%;
  }
}
@media (max-width: 580px) {
  .hot {
    left: 3%;
    top: 21%;
    width: 30px;
    height: 31px;
    background-size: 30px 31px;
  }
}
</style>
